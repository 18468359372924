import React, { useState, useEffect } from "react";
import { Grid, Card } from "@material-ui/core";
import DataService from "../../services/api/data_services";
import Layout from "../../components/layouts/layout";
import MobileSmoothSlider from "../../components/MobileSmoothSlider";
import Infoview from "../../templates/InfoView";
import Megaview from "../../templates/MegaView";
import Miniview from "../../templates/MiniView";
import Button from "../../components/Button";
import Typography from "@material-ui/core/Typography";
import BackArrowIcon from "../../assets/Images/accordian.svg";
import RightArrow from "../../assets/Icons/go.svg";
import clutchLogo from "../../assets/Images/WhatWeDo/clutch-co-vector-logo.svg";
import RightArrowWhite from "../../assets/Icons/accordian_white.svg";
import LeftGrid from "../../templates/LeftGrid";
import MobileMovieSlider from "../../components/MobileMovieSlider";
import { BASE_SITE_URL } from "../../shared/constants";
//Image Banner
import ourTraits from "../../assets/Images/Industry/HealthCare/WhyDigiryteForHealthcare@2x.png";
import ImageBanner from "../../templates/ImageBanner";
import ImageBnr from "../../assets/Images/Industry/HealthCare/healthcare_banner@2x.png";
//Mini Image Banner1
import MiniImageBanner1 from "../../templates/MiniImageBanner1";
import MiniImageBannerBgImg from "../../assets/Images/WhatWeDo/who_we_work_with.png";

//Icon Card List
import IconCardList from "../../templates/IconCardList";
import IconCardListIcon1 from "../../assets/Images/why digiryte/Done.svg";
import IconCardListIcon2 from "../../assets/Images/why digiryte/Group 5811.svg";
import IconCardListIcon3 from "../../assets/Images/why digiryte/Page Under Construction.svg";
import IconCardListIcon4 from "../../assets/Images/why digiryte/Reviews.svg";
import IconCardListIcon5 from "../..//assets/Images/why digiryte/Term of Use.svg";
import IconCardListIcon6 from "../../assets/Images/why digiryte/Unsubscribed.svg";

//PlainCard
import PlainCard from "../../components/SixPlainCard";
import hoverCardImg1 from "../../assets/Images/Industry/HealthCare/Custom EHR_EMR Development.svg";
import hoverCardImg2 from "../../assets/Images/Industry/HealthCare/undraw_medicine_b1ol.svg";
import hoverCardImg3 from "../../assets/Images/Industry/HealthCare/undraw_Mobile_app_re_catg.svg";
import hoverCardImg4 from "../../assets/Images/Industry/HealthCare/undraw_mobile_images_rc0q.svg";
import hoverCardImg5 from "../../assets/Images/Industry/HealthCare/undraw_my_code_snippets_lynx.svg";
import hoverCardImg6 from "../../assets/Images/Industry/HealthCare/undraw_Segment_re_a3e7.svg";

//Mini Image Banner
import MiniImageBanner from "../../templates/MiniImageBanner";
import MiniImgBnrBgImg from "../../assets/Images/contact_us_bg.png";

//Two column contact info
import TwoColumnContactInfo from "../../templates/TwoColumnContactInfo";
import Clm1Img from "../../assets/Icons/phone.svg";
import Clm2Img from "../../assets/Icons/mail.svg";

import clientMovieSliderImage from "../../assets/Images/Industry/HealthCare/OurHealthcareexpertiseincludes_mobile@2x.png";
import ogimage from "../../assets/Images/ogImages/healthcare@2x.png";
// Base url

const HealthCare = () => {
  const [page, setPage] = useState(1);
  const [articleData, setArticleData] = useState([]);
  const [megaviewData, setMegaViewData] = useState([]);
  const [clientStoryData, setClientStoryData] = useState([]);
  const [clientStoryRawData, setClientStoryRawData] = useState([]);
  const [articleRawData, setArticleRawData] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const [articlePage, setArticlePage] = useState(1);
  const [articleLimit, setArticleLimit] = useState(6);
  const [whitePaperRawData, setWhitePaperRawData] = useState([]);
  const [whitePaperPage, setWhitePaperPage] = useState(1);
  const [whitePaperLimit, setWhitePaperLimit] = useState(1);
  const toggleHover = () => {
    setIsHover(!isHover);
  };

  useEffect(() => {
    DataService.getData(
      "/admin/api/all-industries?type=White Paper&industry_type=Healthcare"
    ).then((response) => {
      setMegaViewData(response);
    });
  }, []);

  useEffect(() => {
    DataService.getData(
      `/admin/api/all-industries?limit=3&page=${articlePage}&type=Article&industry_type=Healthcare`
    ).then((response) => {
      setArticleData([...articleData, ...response.data]);
      setArticleRawData(response);
    });
  }, [articlePage]);
  useEffect(() => {
    DataService.getData(
      `/admin/api/all-industries?limit=3&page=${page}&type=Client Story&industry_type=Healthcare`
    ).then((response) => {
      setClientStoryData([...clientStoryData, ...response.data]);
      setClientStoryRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const quotedMiniCardData = {
    title: "Mobile App Dev for Automative Sales App",
    heading:
      "We can’t speak highly enough of Digiryte’s team because they bent over backward to help us launch our product.",
    content: "Founder, Bumper UK",
    footerContent: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>Authentic client review from</div>
        <div style={{ paddingLeft: "5px" }}>
          <img src={clutchLogo} alt="Clutch" height="50px" />
        </div>
      </div>
    ),
  };
  /**
   * Icon Card List Data
   */

  const IconCardListData = [
    {
      icon: IconCardListIcon1,
      altTxt: "28 years of Medical Experience",
      heading: "28 years of Medical Experience",
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
    {
      icon: IconCardListIcon2,
      altTxt: "14 years in Healthcare IT Industry",
      heading: (
        <>
          14 years in Healthcare <br /> IT Industry
        </>
      ),
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
    {
      icon: IconCardListIcon5,
      altTxt: "ISO 9001 & 27001 Certifications",
      heading: "ISO 9001 & 27001 Certifications",
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
    {
      icon: IconCardListIcon4,
      altTxt: "Rich Experience in HIPAA & GDPR Compliant",
      heading: (
        <>
          Rich Experience in HIPAA <br /> & GDPR Compliant
        </>
      ),
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
    {
      icon: IconCardListIcon3,
      altTxt: "Working Experience in NHS Standards",
      heading: (
        <>
          Working Experience in <br /> NHS Standards
        </>
      ),
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
    {
      icon: IconCardListIcon6,
      altTxt: "W3C Accessibility Framework",
      heading: (
        <>
          W3C Accessibility <br /> Framework
        </>
      ),
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
  ];
  const miniviewDataArticle = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    tooltip: "yes",
    tip: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };

  /**
   * Infoview
   */
  /**
   * Image Banner // HIDING TEMPORATILY
   */
  const ImageBannerData = {
    bnrImg: ImageBnr,
    altImg: ImageBnr,
    bnrImgTitle: <> Healthcare</>,
    // component:'Who',
    bnrImgSubTitle: "Industries",
    // servicesColumn1: {
    //   data: [
    //     {
    //       service: "Startups",
    //       link: "/who-we-work-with/startups"
    //     },
    //     {
    //       service: "Finance",
    //       link: "/who-we-work-with/finance"
    //     },
    //     {
    //       service: "Hospitality",
    //       link: "/who-we-work-with/hospitality"
    //     },
    //     {
    //       service: "Not-For-Profit",
    //       link: "/who-we-work-with/not-for-profit"
    //     }
    //   ]
    // },
    // servicesColumn2: {
    //   data: [
    //     {
    //       service: "Healthcare",
    //       link: "/who-we-work-with/healthcare"
    //     },
    //     {
    //       service: "Education",
    //       link: "/who-we-work-with/education"
    //     },
    //     {
    //       service: "Real Estate & PropTech",
    //       link: "/who-we-work-with/real-estate-and-proptech"
    //     },
    //   ]
    // },
    // servicesColumn3: {
    //   data: [
    //     {
    //       service: "Retail and E-commerce",
    //       link: "/who-we-work-with/retail-and-e-commerce"
    //     },
    //     {
    //       service: "Media and Publishing",
    //       link: "/who-we-work-with/media-and-publishing"
    //     },
    //     {
    //       service: "Public Sector",
    //       link: "/who-we-work-with/public-sector"
    //     }
    //   ]
    // }
    servicesColumn1: {
      data: [
        {
          service: "Startups",
          link: "/who-we-work-with/startups",
        },
        {
          service: "Education",
          link: "/who-we-work-with/education",
        }, // this is temporary
        // {
        //   service: "Finance",
        //   link: "/who-we-work-with/finance"
        // },
        // {
        //   service: "Hospitality",
        //   link: "/who-we-work-with/hospitality"
        // },
        // {
        //   service: "Not-For-Profit",
        //   link: "/who-we-work-with/not-for-profit"
        // }
      ],
    },
    servicesColumn2: {
      data: [
        {
          service: "Healthcare",
          link: "/who-we-work-with/healthcare",
        },
        {
          service: "Finance",
          link: "/who-we-work-with/finance",
        }, // this is temporary
        // {
        //   service: "Education",
        //   link: "/who-we-work-with/education"
        // },
        // {
        //   service: "Real Estate & PropTech",
        //   link: "/who-we-work-with/real-estate-and-proptech"
        // },
      ],
    },
    servicesColumn3: {
      data: [
        {
          service: "Retail and E-commerce",
          link: "/who-we-work-with/retail-and-e-commerce",
        },
        // {
        //   service: "Media and Publishing",
        //   link: "/who-we-work-with/media-and-publishing"
        // },
        // {
        //   service: "Public Sector",
        //   link: "/who-we-work-with/public-sector"
        // }
      ],
    },
  };

  const InfoviewData = {
    route: "/what-we-do",
    color: "primary",
    tooltip: "yes",
    width: "11%",
    tag: "h2",
    InfoviewTitle: (
      <div className="ttc">
        {" "}
        Your Healthcare Digital <br />{" "}
        <span className="red-line3letter red-3letter-mbl">Tra</span>nsformation
        Starts Here
      </div>
    ),
    InfoviewContent: (
      <>
        We realize there are two different categories being addressed in this
        complex healthcare industry, one is Healthcare & another is Life
        Science, but the emotions are very closely tied. Coordinating care
        across a health system is a complex business. With internal Healthcare
        subject matter experts we can help you to introduce a new robust system
        into a working facility without disrupting current care delivery.
        <br />
        <br />
        We deliver bespoke software solutions and operational consulting to our
        clients and their patients which results in tangible benefits. We are
        applying our experience in building and securing data to improve access
        to quality health care. In the foreseeable future, machines will assist
        healthcare providers by analysing a much wider variety of data to make
        well informed decisions to deliver better care.
      </>
    ),
  };

  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  const MiniImageBanner3Data = {
    Id: "minibanner1",
    MiniImageBannerBgImg: MiniImgBnrBgImg,
    MiniImageBannerAltTxt: "Contact Us to estimate your product",
    HoverLinkContent: "Contact Us",
    NearHoverLinkContent: " to ",
    PlainContent: "estimate your product",
    route: "/contact-us",
  };

  /**
   * Hover card button
   */

  const PlainCardData = [
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "Custom EHR/EMR Development",
      HoverCardWithBtnsubtitle: " ",
      HoverCardWithBtnIcon: hoverCardImg1,
      HoverCardWithBtnIconAlt: "Custom EHR/EMR Development",
      HoverCardIconClass: "plain-card-button-main-img",
    },
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "Custom Hospital and Medical Inventory Management",
      HoverCardWithBtnIcon: hoverCardImg2,
      HoverCardWithBtnIconAlt:
        "Custom Hospital and Medical Inventory Management",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: " ",
    },
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "Patient Mobile Apps",
      HoverCardWithBtnIcon: hoverCardImg3,
      HoverCardWithBtnIconAlt: "Patient Mobile Apps",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: " ",
    },
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "Hospital/Institution Mobile Apps",
      HoverCardWithBtnIcon: hoverCardImg4,
      HoverCardWithBtnIconAlt: "Hospital/Institution Mobile Apps",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: " ",
    },
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "Telemedicine Apps",
      HoverCardWithBtnIcon: hoverCardImg5,
      HoverCardWithBtnIconAlt: "Telemedicine Apps",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: " ",
    },
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "Healthcare Data Analysis",
      HoverCardWithBtnIcon: hoverCardImg6,
      HoverCardWithBtnIconAlt: "Healthcare Data Analysis",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: " ",
    },
  ];
  //  Mobile movie slider
  const mobMovieSlider = {
    imageUrl: ourTraits,
  };

  //Movie Slider
  const movieSlider = {
    imageUrl: clientMovieSliderImage,
  };
  const MiniImageBanner1Data1 = {
    title: (
      <span>
        <span className="black-line3letter">How</span> we do it
      </span>
    ),
    titleClass: "mini-image-banner-title-area3 min-title-header",
    image: MiniImageBannerBgImg,
    altTxt: "How we do it",
    btnClassName: "image-banner-resize-btn",
    btnLabel: "TAKE A LOOK",
    btnVariant: "contained",
    btnColor: "primary",
    route: "/how-we-do-it",
    underline: false,
    tooltip: "yes",
    tip: "TAKE A LOOK",
    underlineClass: "black-line",
  };
  return (
    <Layout
      seo={{
        title: "Digiryte | Healthcare | Healthcare Digitalisation starts here",
        ogtitle:
          "Digiryte | Healthcare | Healthcare Digitalisation starts here",
        description:
          "We deliver bespoke software solutions and operational consulting to our clients and their patients which results in tangible benefits.",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}`,
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-70 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="What we do"
          routepath="/who-we-work-with"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          Who we work with
        </Typography>
      </div>
      <div className="mt-30">
        <Infoview InfoviewData={InfoviewData} />
      </div>
      <div className="body-container mt-70">
        <h2 className="title-header">
          <span className="red-line3letter red-3letter-mbl">Our</span>{" "}
          Healthcare expertise includes
        </h2>
      </div>
      <Grid
        className="mt-50 body-container hide-in-mobile"
        container
        direction="row"
        spacing={3}
      >
        {PlainCardData.map((item, index) => (
          <Grid className="appsgrid" key={index} item md={4}>
            <Card className="appsgridshadow">
              <PlainCard
                customHeight="140"
                HoverCardWithBtnCustomClass={item.HoverCardWithBtnCustomClass}
                HoverCardWithBtntitle={item.HoverCardWithBtntitle}
                HoverCardWithBtnIcon={item.HoverCardWithBtnIcon}
                HoverCardWithBtnIconAlt={item.HoverCardWithBtnIconAlt}
                HoverCardIconClass={item.HoverCardIconClass}
                HoverCardWithBtnsubtitle={item.HoverCardWithBtnsubtitle}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
      <Grid className="show-only-in-mobile">
        <MobileSmoothSlider movieSlider={movieSlider} />
      </Grid>
      <div className="mt-50">
        <MiniImageBanner miniImageBannerData={MiniImageBanner3Data} />
      </div>

      <div className="body-container mt-70">
        <h3 className="title-header">
          <span className="red-line3letter red-3letter-mbl">Why</span> Digiryte
          for Healthcare
        </h3>
        <div className="mt-50 mbl-hide">
          <IconCardList IconCardListData={IconCardListData} />
        </div>
        <MobileMovieSlider movieSlider={mobMovieSlider} slideid="slide1" />
      </div>
      {clientStoryData && clientStoryData.length > 0 && (
        <div className="mt-70 body-container">
          <h3 className="title-header">
            {" "}
            <span className="red-line3letter red-3letter-mbl">Cli</span>ent
            Stories
          </h3>
          <div className="mt-50"></div>
        </div>
      )}
      {clientStoryData && clientStoryData.length > 0 && (
        <div className="mt-50">
          <Miniview
            miniviewData={clientStoryData}
            miniviewData2={miniviewDataArticle}
            quotedMiniCardData={quotedMiniCardData}
            navigateComponent={<LeftGrid />}
          />
        </div>
      )}
      {clientStoryRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setPage(page + 1);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="Load More"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="Load More"
              tip="Load More"
              tooltip="yes"
            />
          </div>
        </div>
      )}
      {megaviewData && megaviewData.length > 0 && (
        <div className="mt-70 body-container">
          <Megaview MegaviewData={megaviewData} />
        </div>
      )}
      {whitePaperRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setWhitePaperPage(whitePaperPage + 1);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="LOAD MORE"
              tip="LOAD MORE"
            />
          </div>
        </div>
      )}
      <div className="mt-70"></div>
      <div className="mt-20">
        <MiniImageBanner1
          id="one"
          MiniImageBanner1Data={MiniImageBanner1Data1}
        />
      </div>
      {articleData && articleData.length > 0 && (
        <div className="mt-70">
          <div className="body-container">
            <h3 className="title-header ttc">
              <span className="red-line3letter">Rec</span>ommended Reading
            </h3>
          </div>
          <div className="mt-50">
            <Miniview
              miniviewData={articleData}
              total={articleData.total}
              miniviewData2={miniviewDataArticle}
              quotedMiniCardData={quotedMiniCardData}
            />
          </div>
        </div>
      )}
      {articleRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setArticlePage(articlePage + 1);
              setArticleLimit(3);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="LOAD MORE"
              tip="LOAD MORE"
            />
          </div>
        </div>
      )}
      <div className="mt-70">
        <TwoColumnContactInfo
          TwoColumnContactInfoData={TwoColumnContactInfoData}
        />
      </div>
    </Layout>
  );
};

export default HealthCare;
